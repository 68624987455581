<template>
  <div>
    <laboratories-component />
    <footer-component />
  </div>
</template>

<script>
import LaboratoriesComponent from "@/components/laboratories/LaboratoriesComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";

export default {
  name: "Laboratories",
  title: "Laboratories | Baja California Health Tourism",
  components: { FooterComponent, LaboratoriesComponent },
};
</script>

<style scoped></style>
